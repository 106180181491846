@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Montserrat';
}

.App h1 {
  font-family: 'Nunito';
}

.app-nav {
  width: 50vw;
  margin-left: 50vw;
  height: 75px;
  font-size: 2vh;
}

.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2vh;
}

.nav-item {
  list-style: none;
  border: 2px solid #FEBF81;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-link {
  text-decoration: none;
  color: black;
  border: 2px solid #FFA3A5;
  padding: 5px;
  transition: all 0.5s ease;
}

.nav-link:hover {
  background-color: #FFA3A5;
  color: white;
}

.nav-link:hover .nav-item {
  border: 2px solid #FFDC5E;
}

.button-link {
  text-decoration: none;
  color: black;
}

.about-button, .contact-button, .projects-button, .crew-button, .oberman-button, .salama-button {
  width: 225px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  font-size: 2vh;
  text-align: center;
}

.about-button {
  border: 5px solid #FFA3A5;
  transition: all 0.5s ease;
}

.about-button:hover {
  background-color: #FFA3A5;
  color: white;
}

.projects-button {
  border: 5px solid #FEBF81;
  transition: all 0.5s ease;
}

.projects-button:hover {
  background-color: #FEBF81;
  color: white;
}

.contact-button {
  border: 5px solid #FF69EB;
  transition: all 0.5s ease;
}

.contact-button:hover {
  background-color: #FF69EB;
  color: white;
}

.peach {
  color: #FEBF81;
}

.coral {
  color: #FFA3A5;
}

.pink {
  color: #FF86C8;
}

.magenta {
  color: #FF69EB;
}

@media screen and (max-width: 1024px) {

  .about-button, .contact-button, .projects-button, .crew-button, .oberman-button, .salama-button {
    width: 300px;
  }

  .button-link {
    display: inline-block;
  }

  .app-nav {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 375px) {
  .about-button, .contact-button, .projects-button, .crew-button, .oberman-button, .salama-button {
    width: 200px;
  }

  .nav-list {
    padding: 0;
  }
}
.home {
    display: grid;
    grid-template-columns: 40vw 10vw 50vw;
    grid-template-rows: 35vh 25vh 35vh 35vh 35vh 10vh 35vh;
}

.about-box {
    grid-column: 1;
    grid-row: 1;
    margin-left: 10vw;
    margin-top: 1vh;
}

.about-box h1 {
    font-size: 5vh;
    margin-bottom: 0;
}

.about-box p {
    font-size: 2vh;
}

.trh {
    grid-column: 2;
    grid-row: 1;
    position: relative;
}

.pink-line-trh {
    position: absolute;
    left: 25vw;
    background-color: #FF86C8;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

.peach-line-trh {
    position: absolute;
    left: 40vw;
    background-color: #FFBF81;
    height: 65vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-trh {
    position: absolute;
    left: 10vw;
    background-color: #FFDC5E;
    height: 65vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-trh {
    position: absolute;
    left: 55vw;
    background-color: #FF69EB;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

img {
    height: 27vh;
    width: 20vw;
    margin-left: 5vw;
}

.bio-pic {
    grid-column: 3;
    grid-row: 1 / span 2;
    height: 35vh;
    width: auto;
    margin-top: 15vh;
}

.filler-img-one {
    grid-column: 1;
    grid-row: 3;
    text-align: right;
}

.filler-img-two {
    grid-column: 1;
    grid-row: 4;
}

.clh {
    grid-column: 2;
    grid-row: 4;
    position: relative;
}

.peach-line-clh {
    position: absolute;
    top: -50vh;
    left: -25vw;
    background-color: #FFBF81;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.coral-line-clh {
    position: absolute;
    top: -50vh;
    left: -10vw;
    background-color: #FFA3A5;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.filler-img-three {
    grid-column: 1;
    grid-row: 5;
    text-align: right;
}

.work-box {
    grid-column: 3;
    grid-row: 4;
    margin-left: 10vw;
}

.work-box h1 {
    font-size: 5vh;
    margin-bottom: 0;
}

.work-box p {
    font-size: 2vh;
}

.contact-box {
    grid-column: 1;
    grid-row: 7;
    margin-left: 10vw;
    margin-top: 7vh;
}

.contact-box h1 {
    font-size: 5vh;
    margin-bottom: 0;
}

.contact-box p {
    font-size: 2vh;
}

.brh {
    grid-column: 3;
    grid-row: 7;
    position: relative;
}

.pink-line-brh {
    position: absolute;
    left: 15vw;
    bottom: -45vh;
    background-color: #FF86C8;
    height: 75vh;
    width: 3px;
    z-index: -1;
}

.peach-line-brh {
    position: absolute;
    left: 30vw;
    bottom: -45vh;
    background-color: #FFBF81;
    height: 90vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-brh {
    position: absolute;
    left: 0;
    bottom: -45vh;
    background-color: #FFDC5E;
    height: 90vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-brh {
    position: absolute;
    left: 45vw;
    bottom: -45vh;
    background-color: #FF69EB;
    height: 75vh;
    width: 3px;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .home {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
  
    .filler-img-one, .filler-img-two, .filler-img-three, .peach-line-trh, .pink-line-brh, .peach-line-brh, .magenta-line-trh {
      display: none;
    }

  .bio-pic {
    height: auto;
    width: 60vw;
    margin-top: 5vh;
  }

  .work-box, .contact-box {
    margin-top: 5vh;
  }

  .pink-line-trh {
    top: 5vh;
  }

  .yellow-line-trh {
    top: 0vh;
    left: 40vw;
  }

  .coral-line-clh {
    top: -50vh;
    left: -40vw;
  }

  .peach-line-clh {
    top: -40vh;
    left: -25vw;
  }

  .yellow-line-brh {
    left: 40vw;
    bottom: -45vh;
    height: 55vh;
  }

  .magenta-line-brh {
    left: 25vw;
    bottom: -45vh;
    height: 35vh;
  }
}

@media screen and (max-width: 375px) {
    .bio-pic {
        width: 90vw;
        height: auto;
    }

    .work-box, .contact-box {
        margin-top: 2vh;
      }

    .magenta-line-brh {
        height: 40vh;
    }

    .yellow-line-trh {
        top: 12vh;
        left: 20vw;
        height: 80vh;
    }

    .pink-line-trh {
        top: 0vh;
        left: 40vw;
    }

    .coral-line-clh {
        top: -45vh;
        left: -15vh;
    }

    .peach-line-clh {
        display: none;
    }
}
.about {
    display: grid;
    grid-template-columns: 35vw 65vw;
    grid-template-rows: 35vh 30vh 50vh 20vh;
}

.intro {
    grid-column: 1;
    grid-row: 1;
    margin-left: 10vw;
    margin-top: 10vh;
}

.intro h1 {
    font-size: 7vh;
    margin-bottom: 0;
    margin-top: 0;
}

.intro h2 {
    font-size: 3vh;
}

.tra {
    grid-column: 2;
    grid-row: 1;
    position: relative;
}

.pink-line-tra {
    position: absolute;
    left: 40vw;
    background-color: #FF86C8;
    height: 60vh;
    width: 3px;
    z-index: -1;
}

.peach-line-tra {
    position: absolute;
    left: 55vw;
    background-color: #FFBF81;
    height: 65vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-tra {
    position: absolute;
    left: 25vw;
    background-color: #FFDC5E;
    height: 55vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-tra {
    position: absolute;
    left: 10vw;
    background-color: #FF69EB;
    height: 50vh;
    width: 3px;
    z-index: -1;
}

.bla {
    grid-column: 1;
    grid-row: 3;
    position: relative;
}

.peach-line-bla {
    position: absolute;
    left: 25vw;
    top: -15vh;
    background-color: #FFBF81;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.coral-line-bla {
    position: absolute;
    left: 10vw;
    top: -15vh;
    background-color: #FFA3A5;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.about-projects {
    grid-column: 2;
    grid-row: 3;
}

.about-projects h1 {
    font-size: 4vh;
    margin-bottom: 0;
}

.more-about-me {
    font-size: 2vh;
    margin-top: 3vh;
    margin-bottom: 4vh;
}

@media screen and (max-width: 1024px) {
   .magenta-line-tra {
       display: none;
   }

   .about {
    display: grid;
    grid-template-columns: 25vw 25vw 45vw;
    grid-template-rows: 30vh 7vh 50vh 20vh;
    }

    .intro {
        margin-top: 7vh;
        grid-column: 1 / span 2;
    }

    .peach-line-tra {
        left: 65vw;
        height: 55vh;
    }

    .pink-line-tra {
        left: 52vw;
        height: 45vh;
    }

    .yellow-line-tra {
        left: 39vw;
        height: 35vh;
    }

    .about-projects {
        grid-column: 2 / span 2;
    }

    .peach-line-bla {
        height: 120vh;
        left: 7vw;
        top: -30vh;
    }

    .coral-line-bla {
        height: 100vh;
        left: 18vw;
        top: -5vh;
    }
}

@media screen and (max-width: 375px) {
    .about {
        display: flex;
        flex-direction: column;
    }

    .intro {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .about-projects {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
    }

    .peach-line-tra {
        display: none;
    }

    .pink-line-tra {
        top: -25vh;
        left: 87vw;
        height: 60vh;
    }

    .yellow-line-tra {
        top: -25vh;
        left: 65vw;
    }

    .peach-line-bla {
        height: 100vh;
    }

    .coral-line-bla {
        top: 10vh;
        left: 30vw;
        height: 75vh;
    }
}
header {
    text-align: center;
    margin-bottom: 20vh;
    margin-top: 10vh;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.trp {
    position: relative;
}

.pink-line-trp {
    position: absolute;
    left: 65vw;
    top: -5vh;
    background-color: #FF86C8;
    height: 70vh;
    width: 3px;
    z-index: -1;
}

.peach-line-trp {
    position: absolute;
    left: 80vw;
    top: -5vh;
    background-color: #FFBF81;
    height: 190vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-trp {
    position: absolute;
    left: 50vw;
    top: -5vh;
    background-color: #FFDC5E;
    height: 55vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-trp {
    position: absolute;
    left: 95vw;
    top: -5vh;
    background-color: #FF69EB;
    height: 105vh;
    width: 3px;
    z-index: -1;
}

.blp {
    position: relative;
}

.peach-line-blp {
    position: absolute;
    top: -50vh;
    left: 15vw;
    background-color: #FFBF81;
    height: 140vh;
    width: 3px;
    z-index: -1;
}

.coral-line-blp {
    position: absolute;
    top: -20vh;
    left: 25vw;
    background-color: #FFA3A5;
    height: 180vh;
    width: 3px;
    z-index: -1;
}

.project-img {
    width: 20vw;
    height: 20vw;
    margin-right: 7vw;
    margin-left: 10vw;
    border: 3px solid #FFDC5E;
}

.oberman {
    border: 3px solid #FF86C8;
}

.project-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15vh;
}

.project-box h1 {
    font-size: 6vh;
    margin-bottom: 0;
    margin-top: 0;
}

.project-box h2 {
    font-size: 1.5vh;
    margin-bottom: 0;
    margin-top: 0;
}

.project-box p {
    font-size: 2vh;
}

.project-box-right {
    display: inline-block;
}

.project-box-right img {
    margin-left: 7vw;
}

.right-al {
    text-align: right;
}

.right-box {
    position: relative;
}

.crew-button {
    border: 5px solid #FEBF81;
    transition: all 0.5s ease;
  }
  
.crew-button:hover {
    background-color: #FEBF81;
    color: white;
}
  
.oberman-button {
    border: 5px solid #FF69EB;
    position: absolute;
    right: 0;
    transition: all 0.5s ease;
}
  
.oberman-button:hover {
    background-color: #FF69EB;
    color: white;
}
  
.salama-button {
    border: 5px solid #FFA3A5;
    transition: all 0.5s ease;
}
  
.salama-button:hover {
    background-color: #FFA3A5;
    color: white;
}

.footer {
    text-align: center;
    margin-top: 15vh;
    margin-bottom: 5vh;;
}

@media screen and (max-width: 1024px) {
   header {
       width: 700px;
   }

   header h1 {
    font-size: 4vh;
   }

   header p {
    font-size: 2vh;
   }

   .project-img {
    width: 30vw;
    height: 30vw;
    margin-left: 7vw;
}

   .peach-line-trp {
       display: none;
   }

   .pink-line-trp {
    height: 50vh
   }

   .yellow-line-trp {
    left: 80vw;
    height: 75vh
   }

   .magenta-line-trp {
       height: 100vh
   }

   .coral-line-blp {
       height: 130vh
   }

   .peach-line-blp {
    left: 12vw;
   }
}

@media screen and (max-width: 375px) {
    header {
        width: 90%;
        margin-top: 3vh;
        margin-bottom: 5vh;
    }

    .magenta-line-trp {
        top: 225vh;
        left: 85vw;
        height: 40vh;
    }

    .project-box {
        flex-direction: column;
        margin-top: 5vh;
        margin-bottom: 3vh;;
    }

    .project-box-right .project-box {
        flex-direction: column-reverse;
    }

    .project-box div {
        text-align: center;
    }

    .project-img {
        width: 80vw;
        height: 80vw;
    }

    .yellow-line-trp {
        height: 120vh;
    }

    .pink-line-trp {
        left: 60vw;
    }

    .coral-line-blp {
        height: 120vh;
    }

    .peach-line-blp {
        top: 85vh;
        height: 130vh;
        left: 33vw;
    }
}
.not-found {
    display: grid;
    grid-template-columns: 65vw 35vw;
    grid-template-rows: 35vh 20vh 50vh 20vh;
}

.tln {
    grid-column: 1;
    grid-row: 1;
    position: relative;
}

.pink-line-tln {
    position: absolute;
    left: 35vw;
    background-color: #FF86C8;
    height: 50vh;
    width: 3px;
    z-index: -1;
}

.peach-line-tln {
    position: absolute;
    left: 20vw;
    background-color: #FFBF81;
    height: 120vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-tln {
    position: absolute;
    left: 50vw;
    background-color: #FFDC5E;
    height: 70vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-tln {
    position: absolute;
    left: 5vw;
    background-color: #FF69EB;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

.nf-text {
    grid-column: 2;
    grid-row: 1;
    margin-top: 15vh;
}

.nf-projects {
    grid-column: 1;
    grid-row: 3;
    margin-left: 25vw;
}

.brn {
    grid-column: 2;
    grid-row: 3;
    position: relative;
}

.peach-line-brn {
    position: absolute;
    left: 20vw;
    bottom: -10vh;
    background-color: #FFBF81;
    height: 105vh;
    width: 3px;
    z-index: -1;
}

.coral-line-brn {
    position: absolute;
    left: 5vw;
    bottom: -30vh;
    background-color: #FFA3A5;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .yellow-line-tln {
        height: 40vh;
    }

    .nf-text {
        margin-top: 10vh;
    }

    .nf-text h1, .nf-projects h1 {
        font-size: 4vh;
    }

    .nf-text p, .nf-projects p {
        font-size: 2vh;
    }

    .not-found {
        grid-template-columns: 55vw 45vw;
        grid-template-rows: 35vh 5vh 50vh 20vh;
    }

    .peach-line-brn {
        left: 35vw;
        height: 95vh
    }

    .coral-line-brn {
        left: 15vw;
        height: 90vh;
    }

    .pink-line-tln {
        height: 30vh;
    }
}

@media screen and (max-width: 375px) {
    .peach-line-tln, .magenta-line-tln {
        display: none;
    }

    .not-found {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .nf-text {
        margin-top: 2vh;
    }

    .nf-text h1, .nf-projects h1 {
        font-size: 6vh;
    }

    .nf-text p, .nf-projects p {
        font-size: 3vh;
    }

    .nf-projects {
        margin-left: 10px;
        margin-right: 10px;
    }

    .coral-line-brn {
        top: -65vh;
        left: 10vw;
    }

    .peach-line-brn {
        top: -70vh;
        left: 87vw;
        height: 80vh;
    }

    .yellow-line-tln {
        top: 25vh;
        left: 30vw;
        height: 80vh;
    }

    .pink-line-tln {
        left: 60vw;
        top: 85vh;
    }
}
