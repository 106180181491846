@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito&display=swap');

.App {
  font-family: 'Montserrat';
}

.App h1 {
  font-family: 'Nunito';
}

.app-nav {
  width: 50vw;
  margin-left: 50vw;
  height: 75px;
  font-size: 2vh;
}

.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2vh;
}

.nav-item {
  list-style: none;
  border: 2px solid #FEBF81;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-link {
  text-decoration: none;
  color: black;
  border: 2px solid #FFA3A5;
  padding: 5px;
  transition: all 0.5s ease;
}

.nav-link:hover {
  background-color: #FFA3A5;
  color: white;
}

.nav-link:hover .nav-item {
  border: 2px solid #FFDC5E;
}

.button-link {
  text-decoration: none;
  color: black;
}

.about-button, .contact-button, .projects-button, .crew-button, .oberman-button, .salama-button {
  width: 225px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  font-size: 2vh;
  text-align: center;
}

.about-button {
  border: 5px solid #FFA3A5;
  transition: all 0.5s ease;
}

.about-button:hover {
  background-color: #FFA3A5;
  color: white;
}

.projects-button {
  border: 5px solid #FEBF81;
  transition: all 0.5s ease;
}

.projects-button:hover {
  background-color: #FEBF81;
  color: white;
}

.contact-button {
  border: 5px solid #FF69EB;
  transition: all 0.5s ease;
}

.contact-button:hover {
  background-color: #FF69EB;
  color: white;
}

.peach {
  color: #FEBF81;
}

.coral {
  color: #FFA3A5;
}

.pink {
  color: #FF86C8;
}

.magenta {
  color: #FF69EB;
}

@media screen and (max-width: 1024px) {

  .about-button, .contact-button, .projects-button, .crew-button, .oberman-button, .salama-button {
    width: 300px;
  }

  .button-link {
    display: inline-block;
  }

  .app-nav {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 375px) {
  .about-button, .contact-button, .projects-button, .crew-button, .oberman-button, .salama-button {
    width: 200px;
  }

  .nav-list {
    padding: 0;
  }
}