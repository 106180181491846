.about {
    display: grid;
    grid-template-columns: 35vw 65vw;
    grid-template-rows: 35vh 30vh 50vh 20vh;
}

.intro {
    grid-column: 1;
    grid-row: 1;
    margin-left: 10vw;
    margin-top: 10vh;
}

.intro h1 {
    font-size: 7vh;
    margin-bottom: 0;
    margin-top: 0;
}

.intro h2 {
    font-size: 3vh;
}

.tra {
    grid-column: 2;
    grid-row: 1;
    position: relative;
}

.pink-line-tra {
    position: absolute;
    left: 40vw;
    background-color: #FF86C8;
    height: 60vh;
    width: 3px;
    z-index: -1;
}

.peach-line-tra {
    position: absolute;
    left: 55vw;
    background-color: #FFBF81;
    height: 65vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-tra {
    position: absolute;
    left: 25vw;
    background-color: #FFDC5E;
    height: 55vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-tra {
    position: absolute;
    left: 10vw;
    background-color: #FF69EB;
    height: 50vh;
    width: 3px;
    z-index: -1;
}

.bla {
    grid-column: 1;
    grid-row: 3;
    position: relative;
}

.peach-line-bla {
    position: absolute;
    left: 25vw;
    top: -15vh;
    background-color: #FFBF81;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.coral-line-bla {
    position: absolute;
    left: 10vw;
    top: -15vh;
    background-color: #FFA3A5;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.about-projects {
    grid-column: 2;
    grid-row: 3;
}

.about-projects h1 {
    font-size: 4vh;
    margin-bottom: 0;
}

.more-about-me {
    font-size: 2vh;
    margin-top: 3vh;
    margin-bottom: 4vh;
}

@media screen and (max-width: 1024px) {
   .magenta-line-tra {
       display: none;
   }

   .about {
    display: grid;
    grid-template-columns: 25vw 25vw 45vw;
    grid-template-rows: 30vh 7vh 50vh 20vh;
    }

    .intro {
        margin-top: 7vh;
        grid-column: 1 / span 2;
    }

    .peach-line-tra {
        left: 65vw;
        height: 55vh;
    }

    .pink-line-tra {
        left: 52vw;
        height: 45vh;
    }

    .yellow-line-tra {
        left: 39vw;
        height: 35vh;
    }

    .about-projects {
        grid-column: 2 / span 2;
    }

    .peach-line-bla {
        height: 120vh;
        left: 7vw;
        top: -30vh;
    }

    .coral-line-bla {
        height: 100vh;
        left: 18vw;
        top: -5vh;
    }
}

@media screen and (max-width: 375px) {
    .about {
        display: flex;
        flex-direction: column;
    }

    .intro {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .about-projects {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
    }

    .peach-line-tra {
        display: none;
    }

    .pink-line-tra {
        top: -25vh;
        left: 87vw;
        height: 60vh;
    }

    .yellow-line-tra {
        top: -25vh;
        left: 65vw;
    }

    .peach-line-bla {
        height: 100vh;
    }

    .coral-line-bla {
        top: 10vh;
        left: 30vw;
        height: 75vh;
    }
}