.not-found {
    display: grid;
    grid-template-columns: 65vw 35vw;
    grid-template-rows: 35vh 20vh 50vh 20vh;
}

.tln {
    grid-column: 1;
    grid-row: 1;
    position: relative;
}

.pink-line-tln {
    position: absolute;
    left: 35vw;
    background-color: #FF86C8;
    height: 50vh;
    width: 3px;
    z-index: -1;
}

.peach-line-tln {
    position: absolute;
    left: 20vw;
    background-color: #FFBF81;
    height: 120vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-tln {
    position: absolute;
    left: 50vw;
    background-color: #FFDC5E;
    height: 70vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-tln {
    position: absolute;
    left: 5vw;
    background-color: #FF69EB;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

.nf-text {
    grid-column: 2;
    grid-row: 1;
    margin-top: 15vh;
}

.nf-projects {
    grid-column: 1;
    grid-row: 3;
    margin-left: 25vw;
}

.brn {
    grid-column: 2;
    grid-row: 3;
    position: relative;
}

.peach-line-brn {
    position: absolute;
    left: 20vw;
    bottom: -10vh;
    background-color: #FFBF81;
    height: 105vh;
    width: 3px;
    z-index: -1;
}

.coral-line-brn {
    position: absolute;
    left: 5vw;
    bottom: -30vh;
    background-color: #FFA3A5;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .yellow-line-tln {
        height: 40vh;
    }

    .nf-text {
        margin-top: 10vh;
    }

    .nf-text h1, .nf-projects h1 {
        font-size: 4vh;
    }

    .nf-text p, .nf-projects p {
        font-size: 2vh;
    }

    .not-found {
        grid-template-columns: 55vw 45vw;
        grid-template-rows: 35vh 5vh 50vh 20vh;
    }

    .peach-line-brn {
        left: 35vw;
        height: 95vh
    }

    .coral-line-brn {
        left: 15vw;
        height: 90vh;
    }

    .pink-line-tln {
        height: 30vh;
    }
}

@media screen and (max-width: 375px) {
    .peach-line-tln, .magenta-line-tln {
        display: none;
    }

    .not-found {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .nf-text {
        margin-top: 2vh;
    }

    .nf-text h1, .nf-projects h1 {
        font-size: 6vh;
    }

    .nf-text p, .nf-projects p {
        font-size: 3vh;
    }

    .nf-projects {
        margin-left: 10px;
        margin-right: 10px;
    }

    .coral-line-brn {
        top: -65vh;
        left: 10vw;
    }

    .peach-line-brn {
        top: -70vh;
        left: 87vw;
        height: 80vh;
    }

    .yellow-line-tln {
        top: 25vh;
        left: 30vw;
        height: 80vh;
    }

    .pink-line-tln {
        left: 60vw;
        top: 85vh;
    }
}