.home {
    display: grid;
    grid-template-columns: 40vw 10vw 50vw;
    grid-template-rows: 35vh 25vh 35vh 35vh 35vh 10vh 35vh;
}

.about-box {
    grid-column: 1;
    grid-row: 1;
    margin-left: 10vw;
    margin-top: 1vh;
}

.about-box h1 {
    font-size: 5vh;
    margin-bottom: 0;
}

.about-box p {
    font-size: 2vh;
}

.trh {
    grid-column: 2;
    grid-row: 1;
    position: relative;
}

.pink-line-trh {
    position: absolute;
    left: 25vw;
    background-color: #FF86C8;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

.peach-line-trh {
    position: absolute;
    left: 40vw;
    background-color: #FFBF81;
    height: 65vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-trh {
    position: absolute;
    left: 10vw;
    background-color: #FFDC5E;
    height: 65vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-trh {
    position: absolute;
    left: 55vw;
    background-color: #FF69EB;
    height: 80vh;
    width: 3px;
    z-index: -1;
}

img {
    height: 27vh;
    width: 20vw;
    margin-left: 5vw;
}

.bio-pic {
    grid-column: 3;
    grid-row: 1 / span 2;
    height: 35vh;
    width: auto;
    margin-top: 15vh;
}

.filler-img-one {
    grid-column: 1;
    grid-row: 3;
    text-align: right;
}

.filler-img-two {
    grid-column: 1;
    grid-row: 4;
}

.clh {
    grid-column: 2;
    grid-row: 4;
    position: relative;
}

.peach-line-clh {
    position: absolute;
    top: -50vh;
    left: -25vw;
    background-color: #FFBF81;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.coral-line-clh {
    position: absolute;
    top: -50vh;
    left: -10vw;
    background-color: #FFA3A5;
    height: 130vh;
    width: 3px;
    z-index: -1;
}

.filler-img-three {
    grid-column: 1;
    grid-row: 5;
    text-align: right;
}

.work-box {
    grid-column: 3;
    grid-row: 4;
    margin-left: 10vw;
}

.work-box h1 {
    font-size: 5vh;
    margin-bottom: 0;
}

.work-box p {
    font-size: 2vh;
}

.contact-box {
    grid-column: 1;
    grid-row: 7;
    margin-left: 10vw;
    margin-top: 7vh;
}

.contact-box h1 {
    font-size: 5vh;
    margin-bottom: 0;
}

.contact-box p {
    font-size: 2vh;
}

.brh {
    grid-column: 3;
    grid-row: 7;
    position: relative;
}

.pink-line-brh {
    position: absolute;
    left: 15vw;
    bottom: -45vh;
    background-color: #FF86C8;
    height: 75vh;
    width: 3px;
    z-index: -1;
}

.peach-line-brh {
    position: absolute;
    left: 30vw;
    bottom: -45vh;
    background-color: #FFBF81;
    height: 90vh;
    width: 3px;
    z-index: -1;
}

.yellow-line-brh {
    position: absolute;
    left: 0;
    bottom: -45vh;
    background-color: #FFDC5E;
    height: 90vh;
    width: 3px;
    z-index: -1;
}

.magenta-line-brh {
    position: absolute;
    left: 45vw;
    bottom: -45vh;
    background-color: #FF69EB;
    height: 75vh;
    width: 3px;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .home {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
  
    .filler-img-one, .filler-img-two, .filler-img-three, .peach-line-trh, .pink-line-brh, .peach-line-brh, .magenta-line-trh {
      display: none;
    }

  .bio-pic {
    height: auto;
    width: 60vw;
    margin-top: 5vh;
  }

  .work-box, .contact-box {
    margin-top: 5vh;
  }

  .pink-line-trh {
    top: 5vh;
  }

  .yellow-line-trh {
    top: 0vh;
    left: 40vw;
  }

  .coral-line-clh {
    top: -50vh;
    left: -40vw;
  }

  .peach-line-clh {
    top: -40vh;
    left: -25vw;
  }

  .yellow-line-brh {
    left: 40vw;
    bottom: -45vh;
    height: 55vh;
  }

  .magenta-line-brh {
    left: 25vw;
    bottom: -45vh;
    height: 35vh;
  }
}

@media screen and (max-width: 375px) {
    .bio-pic {
        width: 90vw;
        height: auto;
    }

    .work-box, .contact-box {
        margin-top: 2vh;
      }

    .magenta-line-brh {
        height: 40vh;
    }

    .yellow-line-trh {
        top: 12vh;
        left: 20vw;
        height: 80vh;
    }

    .pink-line-trh {
        top: 0vh;
        left: 40vw;
    }

    .coral-line-clh {
        top: -45vh;
        left: -15vh;
    }

    .peach-line-clh {
        display: none;
    }
}